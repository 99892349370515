const widthBase = 1440;
const heightBase = 922;

export const calculateTranslateX = (el: HTMLElement): number => {
  const width = Number(el.getAttribute('data-width'));
  const x = Number(el.getAttribute('data-x'));
  const screenWidth = window.innerWidth;
  const adjustedWidth = (width / widthBase) * screenWidth;

  return x * (screenWidth / widthBase) - (screenWidth - adjustedWidth) / 2;
}

export const calculateTranslateY = (el: HTMLElement): number => {
  const height = Number(el.getAttribute('data-height'));
  const y = Number(el.getAttribute('data-y'));
  const screenHeight = window.innerHeight;
  const adjustedHeight = (height / heightBase) * screenHeight;

  return y * (screenHeight / heightBase) - (screenHeight - adjustedHeight) / 2;
}

export const calculateResize = (width: number, height: number): { width: number; height: number; left: number; top: number } => {
  const screenWidth = window.innerWidth;
  const screenHeight = window.innerHeight;
  const aspectRatio = width / height;
  const adjustedWidth = Math.max((width / widthBase) * screenWidth, width / 2);
  const adjustedHeight = Math.max(adjustedWidth / aspectRatio, height / 2);

  return {
    width: adjustedWidth,
    height: adjustedHeight,
    left: (screenWidth - adjustedWidth) / 2,
    top: (screenHeight - adjustedHeight) / 2,
  };
}
